/* TermsConditions.css */

.container {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  min-height: 100vh;
  padding: 20px;
}

.header {
  display: flex;
  justify-content: space-between; /* Aligns items to the start and end of the container */
  align-items: center; /* Vertically center items */
  margin-bottom: 20px;
}

.logo {
  /* Styles for the Tipzonn logo */
  font-size: 24px; /* Adjust as needed */
}

.content {
  font-family: Arial, sans-serif;
  text-align: left; /* Align content to the left */
}

h1, h2 {
  color: #333;
}

ul {
  list-style-type: disc;
  margin-left: 20px;
}

p {
  line-height: 1.5;
}
