@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

.hero12-max-width {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-top: 80px; /* Add some padding to the container */
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 100px;
  width: 100%; /* Ensure the container takes up the full width */
  height: 100%;
}
.hero12-column {
  gap: 24px;
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 50%; /* Limit the max width to half the container */
}
.hero12-content {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.hero12-container1 {
  display: flex;
  flex: 1; /* Allow the image container to take up available space */
  align-items: center;
  justify-content: center;
  position: relative;
  padding-top: 35%;
  max-width: 50%; /* Limit the max width to half the container */
}
.hero12-image1 {
  left: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  position: absolute;
  flex-grow: 1;
  object-fit: cover;
}
.thq-flex-row1 {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Spread out the children */
  width: 100%; /* Ensure the row takes up the full width */
  gap: 20px; /* Add some space between the items */
}

.hero12-heading1,
.hero12-content1 {
  font-family: 'Poppins', sans-serif;
}
.hero12-heading1 {
  font-weight: 600;
  font-size:xxx-large;
  padding-bottom: 20px;
}
.hero12-content1 {
  font-family: 'Poppins', sans-serif;
  font-size: large;
}

@media(max-width: 991px) {
  .hero12-container1 {
    width: 100%;
    padding-top: 56.25%;
  }
}
@media(max-width: 767px) {
  .hero12-heading1 {
    text-align: center;
  }
  .hero12-content1 {
    text-align: center;
  }
  .hero12-image1 {
    width: 100%;
  }
  .thq-flex-row {
    flex-direction: column;
  }

  .hero12-column {
    max-width: 100%; /* Remove the max-width limit */
    align-items: center; /* Center align the content */
  }
}
