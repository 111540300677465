@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

.navbar2-container {
  width: 100%;
  height: 85px;
  display: flex;
  position: relative;
  justify-content: center;
  background-color: var(--dl-color-theme-neutral-light);
}
.navbar2-navbar-interactive {
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.navbar2-image1 {
  height: 2rem;
}
.navbar2-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.navbar2-links {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.navbar2-burger-menu {
  display: none;
}
.navbar2-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.navbar2-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-twounits);
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-theme-neutral-light);
}
.navbar2-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.navbar2-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.navbar2-logo {
  height: 10rem;
}
.navbar2-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar2-icon02 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.navbar2-links1 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.navbar2-icon-group {
  gap: var(--dl-space-space-twounits);
  display: flex;
}
.navbar2-root-class-name {
  background-color: transparent;
}
.thq-link1 {
  cursor: pointer;
  transition: color 0.3s ease;
  color: #000; /* Set the default color for inactive links */
  text-decoration: none;
  font-family: 'Poppins', sans-serif; /* Apply Poppins font */
}
.thq-link1:hover {
  color: #5ce4e4; /* Set hover color to cyan */
}

@media(max-width: 767px) {
  .navbar2-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .navbar2-desktop-menu {
    display: none;
  }
  .navbar2-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media(max-width: 479px) {
  .navbar2-navbar-interactive {
    padding: var(--dl-space-space-unit);
    padding-right: 16px;
  }
  .navbar2-mobile-menu {
    padding: var(--dl-space-space-unit);
  }
}
