.restaurant-container {
  text-align: center;      
  border-radius: 8px;
  background-color: #5ce4e4;
  font-size: 24px;
  margin: 5px;
  padding: 10px;
  color: #324B4B;
}

.selectWorker-container {
  text-align: left;
  padding-left: 10px;
  margin-top: 20px;
  margin-bottom: 30px;
  font-size: 28px;
  color: #324B4B;
}

.logo-container-main{ 
  text-align: left; /* Align the logo to the left */
  margin-bottom: 16px;
}

.tipzonn-logo-main {
  padding-top: 30px;
  padding-left: 10px;
  width: 160px; /* Adjust the size as needed */
  height: auto;
}

.worker-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 4px;
}

.worker-card {
  flex: 1 1 110px; /* Allow the card to grow and shrink, with an initial width of 110px */
  max-width: 110px; /* Ensure it doesn't exceed this width */
  cursor: pointer;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  margin: auto;
}

.worker-card:hover {
  transform: scale(1.05);
}

.worker-image-wrapper {
  position: relative;
  width: 100%;
  height: 130px;
  border-radius: 8px;
  overflow: hidden;
  margin: 0 auto;
}

.worker-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.worker-name-overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  text-align: center;
}

.worker-name {
  padding: 5px 0;
  font-size: 16px;
}

.tip-all-card {
  background-color: grey;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 110px; /* Ensure the tip-all card behaves the same as worker cards */
  max-width: 110px; /* Ensure it doesn't exceed this width */
  height: 130px;
}

.tip-all {
  font-size: 22px;
  color: #FFFFFF;
}