@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');


  /* body {
    font-family: 'Poppins' , sans-serif ;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #ffffff;
    max-width: 100vw;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
   */
  .container-paymentForm {
    text-align: center;
    width: 100vw;
    min-height: 100vh;
    padding: 20px;
    background-color: rgb(249 250 251);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
  }
  
  .header-paymentForm {
    font-size: 26px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .logo-container {
    text-align: left; /* Align the logo to the left */
  }

  .logo-container-main{ 
    text-align: left; /* Align the logo to the left */
    margin-bottom: 16px;
  }
  
  .tipzonn-logo-main {
    padding-top: 10px;
    width: 160px; /* Adjust the size as needed */
    height: auto;
    margin-bottom: 16px;
  }
  
  .input-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 40px;
  }
  
  .rupee-symbol {
    font-size: 30px;
  }
  
  .payment-input {
    font-size: 30px;
    width: 100px;
    text-align: center;
    border: none;
    outline: none;
    background-color: #fff;
  }
  
  .tip-buttons {
    display: flex;
    justify-content: space-around;
    margin-bottom: 30px;
  
  }
  
  
.tip-button {
  width: 80px;
  height: 56px; /* Increased height */
  font-size: 20px;
  background-color: #ffffff;
  color: #000000;
  border: none;
  border-radius: 8px; /* Increased border-radius */
  cursor: pointer;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tip-button:hover {
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.3);
  transform: translateY(-2px);
}

.tip-button span {
  font-size: 20px;
  margin-left: 2px;
}

.tip-button.selected {
  background-color: #5ce1e6; /* Example color */
  color: white; /* Change text color if needed */
}

.custom-buttons {
  display: flex;
  justify-content: space-around;
  margin-bottom: 50px;
}

.custom-button {
  width: 40%;
  height: 56px;
  font-size: 18px;
  background-color: #ffffff;
  color: #6B7280;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.custom-button:hover {
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.3);
  transform: translateY(-2px);
}

  .payment-btn {
    font-size: 20px;
    width: 100%;
    padding: 10px;
    background-color: #5ce1e6;
    color: #ffffff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 10px;
    margin: 0 auto; /* Center the button horizontally */
    display: block; /* Ensure it takes full width and centers */
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    font-weight: bold;
  }

  .tipzonn-logo {
    padding-left: 10px;
    width: 160px; /* Adjust the size as needed */
    height: auto;
  }
  
/* Notification container */
.notification {
  position: fixed;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px 20px;
  border: 1px solid #f5c6cb;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  max-width: 600px;
  transition: top 0.3s ease;
}

/* Show the notification */
.notification.show {
  top: 20px;
}

/* Close button */
.notification .close-btn {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #721c24;
}
.bottom-sheet-content {
  padding: 16px;
  background: white;
  border-radius: 16px 16px 0 0;
}

.custom-amount-input {
  width: 100%;
  padding: 8px;
  margin-top: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.currency-input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.currency-symbol {
  position: absolute;
  font-size: 16px;
  left: 10px;
  top: 57%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #000000;
}

.custom-amount-input {
  padding-left: 25px; /* Adjust this value to create space for the currency symbol */
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  height: 40px; /* Adjust as needed */
  line-height: 40px; /* Match the height */
  
}

.emoji-buttons {
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
}

.emoji-button {
  width: 70px;
  height: 70px;
  font-size: 20px;
  background-color: rgb(250, 251, 252);
  color: #000000;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.emoji-button:hover {
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.3);
  transform: translateY(-2px);
}

.emoji-button .emoji {
  font-size: 24px;
  margin-bottom: 4px;
}

.emoji-button .percentage {
  font-size: 16px;
}

.emoji-button.active {
  background-color: #5ce1e6;
  color: #ffffff;
}

.worker-photo-container-payment {
  display: flex;
  justify-content: center; /* Center the worker photo */
  margin-bottom: 16px; /* Add some space below the photo */
}

.worker-image-wrapper-payment{
  position: relative;
  width: 130px; /* Ensure the same size as in the worker grid */
  height: 130px;
  border-radius: 8px;
  overflow: hidden;
  margin: 0 auto; /* Center the image wrapper */
  border: 2px solid #5ce1e6; /* Add a border with 2px width and black color */
}

.worker-image-payment {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.worker-name-overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  text-align: center;
}

.worker-name {
  padding: 5px 0;
  font-size: 16px;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.info-card {
  position: absolute;
  bottom: 100%;
  right: 0;
  background-color: white;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  padding: 8px 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 200px;
  font-size: 14px;
  color: #4b5563;
  text-align: left;
}