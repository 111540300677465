.footer151-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
}

.footer151-max-width {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer151-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--dl-space-space-twounits);
}

.footer151-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.footer151-container1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer151-image1 {
  height: 16rem; /* Increase the logo size */
  width: auto;
}

.footer151-credits {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--dl-space-space-twounits);
}

.footer151-row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--dl-space-space-twounits);
}

.footer151-credits1 {
  display: flex;
  gap: var(--dl-space-space-twounits);
  flex-wrap: wrap;
  justify-content: center;
}

.footer151-content3 {
  text-align: center;
}

@media (max-width: 767px) {
  .footer151-image1 {
    height: 6rem; /* Adjust logo size for smaller screens */
  }

  .footer151-credits1 {
    flex-direction: column;
    align-items: center;
  }

  .footer151-row {
    flex-direction: column;
    align-items: center;
  }
}
